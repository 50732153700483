gallery-container {
  display: grid;
  position: relative;
  overflow: hidden;
}
gallery-container::after {
  z-index: 11;
}
gallery-container .slide {
  grid-row: 1;
  grid-column: 1;
  display: grid;
}
gallery-container .slide .image {
  grid-row: 1;
  grid-column: 1;
}
gallery-container .slide:nth-of-type(1) {
  z-index: 10;
}
gallery-container .slide:nth-of-type(2) {
  z-index: 9;
}
gallery-container .slide:nth-of-type(3) {
  z-index: 8;
}
gallery-container .slide:nth-of-type(4) {
  z-index: 7;
}
gallery-container .slide:nth-of-type(5) {
  z-index: 6;
}
gallery-container .slide:nth-of-type(6) {
  z-index: 5;
}
gallery-container .slide:nth-of-type(7) {
  z-index: 4;
}
gallery-container .slide:nth-of-type(8) {
  z-index: 3;
}
gallery-container .slide:nth-of-type(9) {
  z-index: 2;
}
gallery-container .slide:nth-of-type(10) {
  z-index: 1;
}
gallery-container.iterator .slide {
  opacity: 0;
  transition: opacity 1.2s cubic-bezier(0.42, 0, 0.58, 1);
}
gallery-container.iterator .slide.current {
  opacity: 1;
}