@use '_modules' as *;

gallery-container {
  
  display:grid;
  position: relative;
  overflow: hidden;
  $max-slides : 10; 

  &::after { z-index: $max-slides + 1; }

  .slide {
    grid-row:1; 
    grid-column:1;
    display:grid;
    .image {
      grid-row:1; 
      grid-column:1;
    }
    @for $i from $max-slides through 1 {
      &:nth-of-type(#{($max-slides + 1) - $i}) {
        z-index: #{$i}; 
      }
    }
  }

  &.iterator {
    .slide {
      opacity:0; 
      transition: opacity 1.2s $ease-in-out;
      &.current {
        opacity:1; 
      }
    }
  }

}